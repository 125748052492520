import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EMPTY_INTERNAL_CORRECTION_ENTITY, InternalCorrectionEntity } from "domain/entity/InternalCorrection/InternalCorrectionEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { InternalCorrectionRepository } from "domain/repository/InternalCorrection/InternalCorrectionRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { AdjustmentTypeDroOpts } from "presentation/constant/DropDownOptions/Charge/AdjustmentTypeDroOpts";
import { CntrStatusDroOpts } from "presentation/constant/DropDownOptions/Company/CntrStatusDroOpts";
import { createInternalCorrectionDischargeValidationSchema, createInternalCorrectionFixValidationSchema } from "presentation/constant/InternalCorrection/InternalCorrectionMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { InternalCorrectionMaintenanceModel } from "presentation/model/InternalCorrection/InternalCorrectionMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface InternalCorrectionMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<InternalCorrectionMaintenanceModel>> | ((value: SetStateAction<InternalCorrectionMaintenanceModel>) => void),
    ],
    repo: InternalCorrectionRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
    masterDataRepo: MasterDataRepository,
    tariffTypeRepo: TariffTypeRepository,
}

export const InternalCorrectionMaintenanceVM = ({ dispatch, repo, standardTariffCodeRepo, companyRepo,
    tariffTypeRepo, chargeTypeRepo, masterDataRepo }: InternalCorrectionMaintenanceVMProps) => {
    const [internalCorrectionMainDispatch] = dispatch;

    const loadDropdownOption = async () => {

        internalCorrectionMainDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                cntrStatusDropdownOptions: CntrStatusDroOpts().getDroOptsModel()
            }
        }))

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tariffs => {
                let newTariffs = _.orderBy(tariffs, ["tariffType"]);
                let tariffTypeDropdownOption = newTariffs?.map((tariff) => ({
                    dropdownLabel: tariff.tariffType,
                    tagLabel: tariff.tariffType,
                    value: tariff.tariffType,
                })) ?? []
                internalCorrectionMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOption,
                    }
                }))
            }
        );

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                // let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                // let allTariffCodeeDropdownOptions: DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    // const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                    //     t.value === tariff.tariffType);
                    // if (!isTariffTypeExisted) {
                    //     tariffTypeDropdownOptions.push({
                    //         dropdownLabel: tariff.tariffType,
                    //         tagLabel: tariff.tariffType,
                    //         value: tariff.tariffType,
                    //     })
                    // }

                    // const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t =>
                    //     t.value === tariff.tariffCode);

                    // if (!isTariffCodeExisted) {
                    //     allTariffCodeeDropdownOptions.push({
                    //         dropdownLabel: tariff.tariffCode,
                    //         tagLabel: tariff.tariffCode,
                    //         value: tariff.tariffCode,
                    //     })
                    // }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                internalCorrectionMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        // tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                internalCorrectionMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeOnCompanyDropdownOptions: [
                            ...companyCodeDropdownOptions],
                        billToCompanyDropdownOptions: [
                            ...companyCodeDropdownOptions],
                    }
                }))
            }
        )

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);

                let chargeTypeDropdownOption: DropdownProps[] = [];
                let subChargeTypeDropdownOption: { [key: string]: DropdownProps[] } = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                internalCorrectionMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MODALITY).then(
            modalityEntities => {
                const modalityCodeDropdownOptions = modalityEntities?.map((modality) => ({
                    dropdownLabel: modality.code,
                    tagLabel: modality.code,
                    value: modality.code,
                })) ?? []

                internalCorrectionMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        modalityDropdownOptions: modalityCodeDropdownOptions,
                    }
                }))
            }
        )
    }

    const onSearch = async () => {
        const entities = await repo.getEntities();
        const newData = entities.map(entity => ({
            ...entity,
            adjType: AdjustmentTypeDroOpts().getToValueByKey(entity.adjType as string)
        }));

        internalCorrectionMainDispatch(prevState => ({
            ...prevState,
            tableData: newData,
            selectedRows: [],
            currentSelectedRow: EMPTY_INTERNAL_CORRECTION_ENTITY,
            isBackMaster: false,
        }))
    }

    const updateSelectedRows = async (allRows: InternalCorrectionEntity[], selecedRows: InternalCorrectionEntity[]) => {
        internalCorrectionMainDispatch(prevState => {

            return {
                ...prevState,
                tableData: [...allRows],
                selectedRows: selecedRows,
            }
        })
    }

    const onAdd = () => {
        internalCorrectionMainDispatch(prevState => {

            return {
                ...prevState,
                isShowDtl: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_INTERNAL_CORRECTION_ENTITY,
                }
            }
        })
    }

    const onEdit = (currentEntity: InternalCorrectionEntity) => {
        internalCorrectionMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDtl: true,
                currentSelectedRow: currentEntity,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }
    const onEditClicked = () => {
        internalCorrectionMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                }
            }
        })
    }

    const onReset = () => {
        internalCorrectionMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_INTERNAL_CORRECTION_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        internalCorrectionMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDtl: false,
                currentSelectedRow: EMPTY_INTERNAL_CORRECTION_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_INTERNAL_CORRECTION_ENTITY,
                    allFormState: {}
                },
                isBackMaster: true,
            }
        })
    }

    const onSaveClicked = () => {
        internalCorrectionMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: InternalCorrectionEntity, isAdd: boolean) => {
        const valResult = await Validation(currentEntity.correction === ChargeConstantCollection.correction.DISCHARGE
            ? createInternalCorrectionDischargeValidationSchema : createInternalCorrectionFixValidationSchema).ValidateFormOnly(currentEntity);
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            internalCorrectionMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });

            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }

        currentEntity = {
            ...currentEntity,
            adjType: AdjustmentTypeDroOpts().getToKeyByValue(currentEntity.adjType as string)
        }

        if (isAdd) {
            return await repo.createEntity(currentEntity);
        } else {
            return await repo.updateEntity(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        internalCorrectionMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        internalCorrectionMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }
    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        internalCorrectionMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate,
                },
            }
        }))
    }

    const onCorrectionChanged = () => {
        internalCorrectionMainDispatch(prevState => {
            let correctionType = prevState.masterState.editingEntity.correctionType;
            let correctionAmount = prevState.masterState.editingEntity.correctionAmount;
            if (prevState.masterState.editingEntity.correction === ChargeConstantCollection.correction.FIX) {
                correctionType = null;
            } else {
                correctionAmount = null;
            }
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        correctionType: correctionType,
                        correctionAmount: correctionAmount,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        correctionType: '',
                        correctionAmount: '',
                    }
                }
            }
        })
    }


    return {
        onEditClicked: onEditClicked,
        onDateRangeChange: onDateRangeChange,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onCorrectionChanged: onCorrectionChanged,
    }
}