import { SelectionChangedEvent } from "ag-grid-community";
import { InternalCorrectionEntity } from "domain/entity/InternalCorrection/InternalCorrectionEntity";
import { INITIAL_INTERNAL_CORRECTION_COL_DEF } from "presentation/constant/InternalCorrection/InternalCorrectionMaintenanceColumnDefinition";
import { InternalCorrectionMaintenanceConstant } from "presentation/constant/InternalCorrection/InternalCorrectionMaintenanceConstant";
import { useInternalCorrectionMaintenanceVM } from "presentation/hook/InternalCorrection/useInternalCorrectionMaintenanceVM";
import { useInternalCorrectionMaintenanceTracked } from "presentation/store/InternalCorrection/InternalCorrectionMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";

const InternalCorrectionTablePanel: React.FC = () => {
    const [internalCorrectionState] = useInternalCorrectionMaintenanceTracked();
    const internalCorrectionVM = useInternalCorrectionMaintenanceVM();
    const gridRef: any = useRef(null);
    const { tableData } = internalCorrectionState;

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        internalCorrectionVM.updateSelectedRows(tableData, selectedRows);
    }, [internalCorrectionVM, tableData])

    const handleAddClick = useCallback(() => {
        internalCorrectionVM.onAdd();
    }, [internalCorrectionVM])

    const handleRowDoubleClick = useCallback((entity: InternalCorrectionEntity) => {
        internalCorrectionVM.onEdit(entity);
    }, [internalCorrectionVM])

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='internal-correction-table'
                headerLabel={InternalCorrectionMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_INTERNAL_CORRECTION_COL_DEF}
                data={tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: InternalCorrectionEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}

export default memo(InternalCorrectionTablePanel);
