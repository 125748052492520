import { DataMappingEntity } from "../Common/DataMappingEntity"

export interface InternalCorrectionEntity extends DataMappingEntity {
    id: number,
    docType: string | null,
    adjType: string | null,
    chargeOnCompany: string | null,
    billToCompany: string | null,
    chargeType: string | null,
    subChargeType: string | null,
    modalityOfArrival: string | null,
    modalityOfDeparture: string | null,
    cntrStatus: string | null,
    tariffType: string | null,
    tariffCode: string | null,
    recalInd: string | null,
    revenueCompanyCode: string | null,
    revenueAccountCode: string | null,
    costCenterCode: string | null,
    productServiceCode: string | null,
    salesChannelCode: string | null,
    countryCode: string | null,
    intercompanyCode: string | null,
    projectCode: string | null,
    spareCode: string | null,
    remarkForDocument: string | null,
    remarkForEstimation: string | null,
    correctionType: string | null,
    correctionAmount: number | null,
    correction: string | null,

    [key: string]: string | number | null | undefined | Date
}

export const EMPTY_INTERNAL_CORRECTION_ENTITY: InternalCorrectionEntity = {
    id: 0,
    docType: '',
    adjType: '',
    chargeOnCompany: '',
    billToCompany: '',
    chargeType: '',
    subChargeType: '',
    modalityOfArrival: '',
    modalityOfDeparture: '',
    cntrStatus: '',
    tariffType: '',
    tariffCode: '',
    recalInd: '',
    revenueCompanyCode: null,
    revenueAccountCode: null,
    costCenterCode: null,
    productServiceCode: null,
    salesChannelCode: null,
    countryCode: null,
    intercompanyCode: null,
    projectCode: null,
    spareCode: null,
    remarkForDocument: null,
    remarkForEstimation: null,
    correctionType: null,
    correctionAmount: null,
    correction: 'FIX',
    priority: 0,
    effectiveDate: null,
    expiryDate: null,
    confirmedDate: null,
    activeInd: 'Y',
    mappingType: "InternalCorrectionMappingEntity"
}

