import { ChargeConstantCollection } from 'constants/charge/ChargeConstantCollection';
import * as yup from 'yup';


export const commonInternalCorrectionMaintenanceValidation: { [x: string]: any; } = {
  revenueCompanyCode: yup.string().required("Missing"),
  revenueAccountCode: yup.string().required("Missing"),
  costCenterCode: yup.string().required("Missing"),
  productServiceCode: yup.string().required("Missing"),
  salesChannelCode: yup.string().required("Missing"),
  countryCode: yup.string().required("Missing"),
  intercompanyCode: yup.string().required("Missing"),
  projectCode: yup.string().required("Missing"),
  spareCode: yup.string().required("Missing"),
  correction: yup.string().required("Missing").oneOf(Object.values(ChargeConstantCollection.correction)),
  // correctionType: yup.string().when('correction', (correction , schema) => {
  //   return !_.isEmpty(correction) && correction[0] === ChargeConstantCollection.correction.DISCHARGE ? schema.required("Missing") : schema;
  // }),  
  // correctionAmount: yup.number().when('correction', (correction , schema) => {
  //   return !_.isEmpty(correction) && correction[0] === ChargeConstantCollection.correction.FIX ? schema.required("Missing") : schema;
    
  // })
};

export const commonInternalCorrectionDischargeValidation: { [x: string]: any; } = {
  ...commonInternalCorrectionMaintenanceValidation,
  correctionType: yup.string().required("Missing"),
};

export const commonInternalCorrectionFixValidation: { [x: string]: any; } = {
  ...commonInternalCorrectionMaintenanceValidation,
  correctionAmount: yup.number().required("Missing"),
};

export const createInternalCorrectionDischargeValidation: { [x: string]: any; } = {
  ...commonInternalCorrectionDischargeValidation,
};
export const createInternalCorrectionFixValidation: { [x: string]: any; } = {
  ...commonInternalCorrectionFixValidation,
};

export const createInternalCorrectionDischargeValidationSchema = yup.object().shape(createInternalCorrectionDischargeValidation);
export const createInternalCorrectionFixValidationSchema = yup.object().shape(createInternalCorrectionFixValidation);

