import { AdjustmentTypePolicy } from "constants/charge/AdjustmentTypePolicy";
import _ from "lodash";

export const AdjustmentTypeDroOpts = () => {
    const ADJ_TYPE_CANCEL = {[AdjustmentTypePolicy.ADJ_TYPE_CANCEL]: AdjustmentTypePolicy.TEXT_CANCEL};
    const ADJ_TYPE_CANCEL_REBILL = {[AdjustmentTypePolicy.ADJ_TYPE_CANCEL_REBILL]: AdjustmentTypePolicy.TEXT_CANCEL_REBILL};
    const ADJ_TYPE_ADJUSTMENT = {[AdjustmentTypePolicy.ADJ_TYPE_ADJUSTMENT]: AdjustmentTypePolicy.TEXT_ADJUSTMENT};
    const ADJ_TYPE_NORMAL = {[AdjustmentTypePolicy.ADJ_TYPE_NORMAL]: AdjustmentTypePolicy.TEXT_NORMAL};
    const ADJ_TYPE_RECAL = {[AdjustmentTypePolicy.ADJ_TYPE_RECAL]: AdjustmentTypePolicy.TEXT_RECAL};


    const getAdjustmentTypeModel = () => {
        let items:{ [key: string]: string } = {};
        items = {
            ...ADJ_TYPE_CANCEL, 
            ...ADJ_TYPE_CANCEL_REBILL, 
            ...ADJ_TYPE_ADJUSTMENT, 
            ...ADJ_TYPE_NORMAL, 
            ...ADJ_TYPE_RECAL
        };

        return [
            ...(_.map(
                _.toPairs(items), ([key, value]) => (
                    { dropdownLabel: value, tagLabel: key, value: value }
                )
            ) ?? [])
        ];

    }

    const getToValueByKey = (key: string) => {
        return  getAdjustmentTypeModel().find(item => item.tagLabel === key)?.value??"";    
    }

    const getToKeyByValue = (value: string) => {
        return  getAdjustmentTypeModel().find(item => item.value === value)?.tagLabel??"";        
    }

    return {
        getAdjustmentTypeModel,
        getToValueByKey,
        getToKeyByValue
    }
}