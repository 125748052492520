import { EMPTY_MASTER_INTERNAL_CORRECTIONANY_MODEL } from "presentation/model/InternalCorrection/InternalCorrectionMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: InternalCorrectionMaintenanceProvider,
    useTracked: useInternalCorrectionMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_INTERNAL_CORRECTIONANY_MODEL), {concurrentMode: true});
export { InternalCorrectionMaintenanceProvider, useInternalCorrectionMaintenanceTracked };

