import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { InternalCorrectionMaintenanceProvider } from "presentation/store/InternalCorrection/InternalCorrectionMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { InternalCorrectionMasterView } from "presentation/view/section/InternalCorrection/InternalCorrectionMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const InternalCorrectionMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.INTERNAL_CORRECTION_MAINTENANCE}>
        <MessageBarWrapper>
            <InternalCorrectionMaintenanceProvider>
                <GridStyles/>
                <InternalCorrectionMasterView/>
            </InternalCorrectionMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default InternalCorrectionMaintenanceCont;