
import { useInternalCorrectionMaintenanceTracked } from "presentation/store/InternalCorrection/InternalCorrectionMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import InternalCorrectionMaintenance from "./InternalCorrectionMaintenance";
import { InternalCorrectionMaintenanceFormPanel } from "./InternalCorrectionMaintenanceFormPanel";

export const InternalCorrectionMasterView: React.FC = () => {
    const [internalCorrectionState] = useInternalCorrectionMaintenanceTracked();

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <InternalCorrectionMaintenance />
            {
                ((internalCorrectionState.isShowDtl) &&
                    <SliderPanel
                        isOpen={false}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<InternalCorrectionMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
}