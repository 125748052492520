import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { InternalCorrectionRepoImpl } from "domain/repository/InternalCorrection/InternalCorrectionRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { useInternalCorrectionMaintenanceTracked } from "presentation/store/InternalCorrection/InternalCorrectionMaintenanceProvider";
import { InternalCorrectionMaintenanceVM } from "presentation/viewModel/InternalCorrection/InternalCorrectionMaintenanceVM";
import { useMemo } from "react";

export const useInternalCorrectionMaintenanceVM = () => {
    const [, setInternalCorrectionMaintenanceState] = useInternalCorrectionMaintenanceTracked();
    const internalCorrectionMainVM = useMemo(() =>
        InternalCorrectionMaintenanceVM({
            dispatch: [setInternalCorrectionMaintenanceState],
            repo: InternalCorrectionRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            tariffTypeRepo: TariffTypeRepoImpl(),
        }), [setInternalCorrectionMaintenanceState])

    return internalCorrectionMainVM
}

