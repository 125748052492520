export const internalCorrectionMaintenanceRequiredFieldList: string[] = [
    'internalCorrection',
];

export const InternalCorrectionMaintenanceConstant = {
    CATEGORY: "Month-End",
    TITLE: "Internal Correction",
    TARIFF_TYPE: "Tariff Type",
    TARIFF_CODE: "Tariff Code",
    DOC_TYPE: "Doc Type",
    ADJ_TYPE: "Adj. Type",
    CHARGE_ON_COMPANY: "Charge on Company",
    BILL_TO_COMPANY: "Bill to Company",
    CHARGE_TYPE: "Charge Type",
    SUB_CHARGE_TYPE: "Sub Charge Type",
    MODALITY_OF_ARRIVAL: "Modality of Arrival",
    MODALITY_OF_DEPARTURE: "Modality of Departure",
    CONTAINER_STATUS: "Container Status",
    RECAL: "Recal",
    REVENUE_COMPANY_CODE: "Revenue Company Code",
    REVENUE_AC_CODE: "Revenue A/C Code",
    COST_CENTER_CODE: "Cost Center Code",
    PRODUCT_SERVICE_CODE: "Product Service Code",
    SALES_CHANNEL_CODE: "Sales Channel Code",
    COUNTRY_CODE: "Country Code",
    INTERCOMPANY_CODE: "Inter-company Code",
    PROJECT_CODE: "Project Code",
    SPARE_CODE: "Spare Code",
    CORRECTION: "Correction ",
    CORRECTION_TYPE: "Correction Type",
    CORRECTION_AMOUNT: "Correction Amount",
    REMARK_FOR_DOCUMENT: "Remark For Document",
    REMARK_FOR_ESTIMATION: "Remark For Estimation",
    ACTIVE_IND: "Active Ind.",
}
