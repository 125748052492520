
import { EMPTY_INTERNAL_CORRECTION_ENTITY, InternalCorrectionEntity } from "domain/entity/InternalCorrection/InternalCorrectionEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface InternalCorrectionDropdownOptions {
    cntrStatusDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    chargeOnCompanyDropdownOptions: DropdownProps[],
    billToCompanyDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    modalityDropdownOptions: DropdownProps[],

}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: InternalCorrectionEntity,
}

export interface InternalCorrectionMaintenanceModel {
    isLoading: boolean,
    isShowDtl: boolean,
    tableData: InternalCorrectionEntity[],
    currentSelectedRow: InternalCorrectionEntity,
    selectedRows: InternalCorrectionEntity[],
    dynamicOptions: InternalCorrectionDropdownOptions,
    masterState: MasterChangeState,
    isBackMaster: boolean,
}

export const EMPTY_MASTER_INTERNAL_CORRECTIONANY_MODEL: InternalCorrectionMaintenanceModel = {
    isLoading: false,
    isShowDtl: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_INTERNAL_CORRECTION_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        cntrStatusDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        chargeOnCompanyDropdownOptions: [],
        billToCompanyDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        modalityDropdownOptions: [],
        tariffCodeDropdownOptions: {}
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_INTERNAL_CORRECTION_ENTITY,
    },
    isBackMaster: false,
}